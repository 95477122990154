import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import unitsData from  './data/unitsData.json'

const Units = () => {
  const units = unitsData
  const [selectedUnitId, setSelectedUnitId] = useState(null);

  const handleUnitClick = (id) => {
    setSelectedUnitId(id);
  };
  
  const getMessage = () => {
    switch (selectedUnitId) {
        case 1:
            return "Has elegido el Universo “Sabiduría” este universo esta lleno de vastos conocimientos y enseñanzas profundas. Aquí la verdadera sabiduría no solo se encuentra en la acumulación de datos, sino en la aplicación práctica de ese conocimiento en la vida cotidiana. "
        case 2:
            return "Te has aventurado hacia el universo “Mágico “ aquí,  es un lugar lleno de maravillas y sorpresas, donde encontraras seres fantásticos y aprendizajes que desafían a cada individuo, en este universo se muestra la belleza de lo inesperado y la posibilidad de lo imposible."
        case 3:
            return "Te encuentras en el universo llamado “Encantado“.  En este lugar, estas en un ambiente utópico donde todos los individuos experimetan la armonía y bondad. Aquí, el valor supremo es el bienestar de los demás, y cada acción esta impulsada por el deseo de ayudar al prójimo"
        default:
            return "Hola soy Alex, un astronauta perdido en el espacio después de una falla en mi nave. He descubrierto tres universos distintos mientras intento encontrar el camino de regreso a casa. Para volver, debo aprender de cada uno y obtener al menos una estrella de cada universo. ¿Quieres acompañarme en esta aventura para ganar una estrella?";
    }
  }

  let message = getMessage()

  return (
    <div className="h-screen py-10 bg-slate-100">
      <img src={"./assets/HumaniTeens.png"} class="absolute w-32 top-5 left-5 h-16"/>
      <div className="absolute top-5 left-56 w-32 h-auto">
        <img
          src="./assets/astronauta.png"
          className=""
        />
      </div>
      <div className="flex justify-center items-center h-40">
        <p className="text-l font-semibold w-[800px] p-4 pl-32">{message}</p>
      </div>
      <div className="flex justify-center items-center py-10">
        {units.map((unit, index) => (
          <div 
            key={index} 
            onClick={() => handleUnitClick(unit.id)} 
            className="bg-slate-900 shadow-lg rounded-lg w-64 m-4"
          >
            <img src={"./assets/" + unit.image} alt={`Image ${index + 1}`} className={`w-full h-40 object-cover rounded-t-lg ${ selectedUnitId === unit.id ? "drop-shadow-xl brightness-150" : "brightness-75"}`} />
            <div className="p-4">
              <h2 className="text-slate-100 m-2">{unit.title}</h2>
              {selectedUnitId === unit.id && (
                <Link className="m-2 bg-blue-500 text-white px-4 py-2 rounded-full" to={"/universo/" + unit.id}>Iniciar</Link>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Units;