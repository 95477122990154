import React, { useState } from 'react';

const Stars = ({ examData }) => {

    console.log(examData) 

    const getTime = () => {
        // Calculate the time difference in milliseconds
        const timeDifference = examData.endTime - examData.startTime;

        // Convert the time difference to minutes
        const minutesDifference = Math.ceil(timeDifference / (1000 * 60));
        const hours = Math.floor(minutesDifference / 60);
        const remainingMinutes = minutesDifference % 60;

        if (hours > 0) {
            if (remainingMinutes > 0) {
                return `${hours} ${hours > 1 ? 'horas' : 'hora'} ${remainingMinutes} ${remainingMinutes > 1 ? 'minutos' : 'minuto'}`;
            } else {
                return `${hours} ${hours > 1 ? 'horas' : 'hora'}`;
            }
        } else {
            if (minutesDifference === 1) {
                return `${minutesDifference} minuto`;
            } else {
                return `${minutesDifference} minutos`;
            }
        }
    }

    const getPoints = () => {
        
        let points = 0;

        for (const [questionId, answer] of Object.entries(examData.answers)) {
            points += getAnswer(questionId, answer);
        }

        return points
    }

    const getStars = (points) => {
        // Determine the star rating based on the total points
        if (points < 10) {
            return 0; // 0 stars
        } else if (points >= 10 && points < 14) {
            return 1; // 1 star
        } else if (points >= 14 && points < 18) {
            return 2; // 2 stars
        } else {
            return 3; // 3 stars
        }
    }

    const getAnswer = (questionId, answer) => {
        const question = examData.questions.find((q) => q.id === parseInt(questionId));

        if (question.answer === answer) {
            return 1;
        } else if (question.answer === true && answer == 1) {
            return 1;
        } else if (question.answer === false && answer == 2) {
            return 1;
        } else {
            return 0;
        }
    }

    const getMessage = () => {
        switch (examData.unit) {
            case "1":
                return "¡Buen trabajo! Has sido participe del Universo “Sabiduría”, experimentaste la importancia de la reflexión y la experiencia personal en la toma de decisiones significativas y en la forma de vivir, no solo en acumular datos, sino en la aplicación en la vida cotidiana."
            case "2":
                return "¡Excelente! Has contribuido a la construcción de mi nave, en el universo “Mágico”, encontraste maravillosa información en el que el conocimiento es mágico y sorprendente descubriendo seres fantásticos y aprendizajes que desafían a cada individuo."
            case "3":
                return "¡Bien hecho! Ya concluiste con el universo “Encantado”, experimentaste un ambiente diferente donde todos los individuos aprenden de la armonía y bondad. Aquí, el valor supremo es el bienestar y cada acción esta impulsada por el deseo de ayudar al prójimo."
            default:
                return null;
        }
    }

    let points = getPoints()
    let stars = getStars(points)
    let message = getMessage()

    return (
        <div className="bg-white p-4 rounded-lg">
            <p className="text-l font-bold mb-2 text-center">Hola { examData.name } del grupo { examData.group } </p>
            <p className="text-l font-bold mb-2 text-center">Obtuviste { points } puntos, equivalentes a {stars} { stars > 2 ? "estrellas" : "estrella" } </p>
            <div className="grid grid-cols-5 gap-4 justify-center items-center">
                <div>
                    <img src="../assets/astronauta.png" className="" />
                </div>
                <img
                    src="../assets/2B50_color.png"
                    className={`w-64 mx-auto ${stars >= 1 ? "grayscale-0" : "grayscale"}`}
                    alt="Star"
                />
                <img
                    src="../assets/2B50_color.png"
                    className={`w-64 mx-auto ${stars >= 2 ? "grayscale-0" : "grayscale"}`}
                    alt="Star"
                />
                <img
                    src="../assets/2B50_color.png"
                    className={`w-64 mx-auto ${stars === 3 ? "grayscale-0" : "grayscale"}`}
                    alt="Star"
                />
                <div>
                    <p className="p-2 border border-black rounded-lg">{message}</p>
                </div>
            </div>
            <p className="text-l font-bold mb-2 text-center">Y en la pregunta final, tu respuesta fue:</p>
            <p className="text-l font-bold mb-2 text-center">{examData.openQuestion}</p>
        </div>
    );
}

export default Stars;
